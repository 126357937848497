@import url("https://fonts.googleapis.com/css?family=Muli");

.b1 {
  position: relative;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-image: url(CommunityFoodNetwork_Hero.svg);
  width: 100%;
  min-height: 110vh;
  text-align: center;
  image-rendering: pixelated;
}

.helpbutton {
  line-height: 12px;
  font-size: 8pt;
  font-family: tahoma;
  margin-top: 5px;
  margin-right: 2%;
  position: absolute;
  top: 0;
  right: 0;
}

.covidbutton {
  line-height: 12px;
  font-size: 8pt;
  font-family: tahoma;
  margin-top: 5px;
  margin-left: 2%;
  position: absolute;
  top: 0;
  left: 0;
}

.stuff {
  display: inline-block;
  min-height: 100%;
  padding-top: 40px;
  min-width: 95%;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  margin-top: 8vh;
}

.titleImage {
  margin-top: calc(5px + (15 - 5) * ((100vw - 300px) / (1600 - 300)));
  width: 40%;
  image-rendering: auto;
}

.stuff h4 {
  font-size: large;
  position: center;
  font-family: "Muli", sans-serif;
  font-weight: normal;
  color: white;
}

.stuff h3 {
  font-size: large;
  position: center;
  font-family: "Muli", sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: white;
  padding-bottom: 0px;
  padding-top: 15px;
}

.guide {
  position: center;
  padding-bottom: calc(8px + (12 - 6) * ((100vw - 300px) / (1600 - 300)));
  padding-top: calc(12px + (18 - 6) * ((100vw - 300px) / (1600 - 300)));
  font-family: "Muli", sans-serif;
  font-weight: bold;
  color: white;
  font-size: calc(15px + (28 - 15) * ((100vw - 300px) / (1600 - 300)));
  /* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))) */
}

.select {
  padding-bottom: calc(12px + (18 - 6) * ((100vw - 300px) / (1600 - 300)));
  padding-top: calc(8px + (12 - 6) * ((100vw - 300px) / (1600 - 300)));
  font-size: calc(13px + (25 - 13) * ((100vw - 300px) / (1600 - 300)));

  position: center;
  font-family: "Muli", sans-serif;
  font-weight: normal;
  color: white;
}

.ButtonClass {
  position: center;
  margin-top: 30px;
  -webkit-animation-delay: 1.3s;
}

.buttonsAll {
  display: flex;
  justify-content: space-between;
}

.startquiz {
  position: fixed;
  z-index: 2;
  bottom: 30px;
  right: -150px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  transition: all 0.5s ease-in-out;
}
#helper {
  z-index: 50;
  font-size: 15px;
  font-weight: 600;
  color: #21ba45;
  position: fixed;
  bottom: 80px;
  right: -150px;
  transition: all 0.58s ease-in-out;
}

.footerBlack{
  color: black !important;
}

.resourceButton1 {
  -webkit-animation-delay: 0.1s;
}

.resourceButton2 {
  -webkit-animation-delay: 0.4s;
}

.resourceButton3 {
  -webkit-animation-delay: 0.7s;
}

.resourceButton4 {
  -webkit-animation-delay: 1s;
}

/* screenwidth less than 1024px
--------------------------------------------------------------------- */
@media only screen and (max-width: 1024px) {
}

/* screenwidth less than 900px
--------------------------------------------------------------------- */
@media only screen and (max-width: 900px) {
}

/* mobile wide/smaller tablets
---------------------------------------------------------------------- */

@media only screen and (max-width: 767px) {
}

/* mobile narrow
-------------------------------------------------------------------------- */

@media only screen and (max-width: 600px) {
  .buttonsAll {
    display: block;
  }

  .resourceButton1 {
    display: block;
    margin-bottom: 1em;
  }

  .resourceButton2 {
    display: block;
    margin-bottom: 1em;
  }

  .resourceButton3 {
    display: block;
  }
}

html {
  scroll-behavior: smooth;
}
